/* eslint-disable react/no-this-in-sfc */
/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import Highcharts from 'highcharts';
import {
  HighchartsChart, withHighcharts, XAxis, YAxis, ColumnSeries, LineSeries,
} from 'react-jsx-highcharts';

import styles from './stock-information-section.module.scss';
import './reset-chart.scss';

import Container from '../../../layouts/container/container';
import VerticalLines from '../../../components/vertical-lines/ vertical-lines';
import { replaceSpace, parseArabic, toAr } from '../../../helpers/helpers';
import LineIndicator from '../../../components/line-indicator/line-indicator';

const StockInformationSection = ({
  activeSection, title, index, data, isMobile, sectionName, Pagination, location, charts,
}) => {
  const initialArray = Array(charts.length).fill(0);
  const [localData, setLocalData] = useState(initialArray);
  const [dates, setDates] = useState([]);
  const [windowSize, setWindowSize] = useState(1024);
  const [isShown, setIsShown] = useState(false);
  const [lineHeight, setLineHeight] = useState(0);
  const handleWindowSizeChange = () => {
    const width = window.innerWidth || document.documentElement.clientWidth
  || document.body.clientWidth;
    setWindowSize(width);
  };
  const handleWindowChange = () => {
    const pagination = document.querySelector(`.${styles.animateIn} > div`);
    const topPosition = pagination
      ? `calc(${window.getComputedStyle(pagination).top} + 60px)` : 0;
    setLineHeight(topPosition);
  };
  const isMob = windowSize < 600;
  const isAr = location.pathname.indexOf('/ar') === 0;

  useEffect(() => {
    if (isMobile) {
      handleWindowSizeChange();
      window.addEventListener('resize', handleWindowSizeChange);
      window.addEventListener('scroll', handleWindowSizeChange);
    }
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
      window.removeEventListener('scroll', handleWindowSizeChange);
    };
  }, [isMobile]);
  useEffect(() => {
    if (lineHeight !== 0) {
      window.removeEventListener('scroll', handleWindowChange);
    }
  }, [lineHeight]);

  const manageData = (arrayData, todo) => {
    let nums = localData;
    const time = 5;
    const increaser = 1;
    const interval = setInterval(() => {
      nums = nums.map((num, i) => {
        if (todo === 'add') { // work if show section; increase all numbers from 0 to data
          if (num !== arrayData[i]) {
            return num + increaser;
          }
        } else if (todo === 'remove') { // work if hide section; increase all numbers from data to 0
          if (num > 0) {
            return num - increaser;
          }
        }
        return num;
      });
      setLocalData(nums);
      const isEqual = arrayData.toString() === nums.toString();
      if (isEqual) {
        clearInterval(interval);
      }
    }, time);
  };
  const datesCallback = useCallback(() => {
    const d = [];
    // eslint-disable-next-line array-callback-return
    charts.map((item) => {
      const { node: { acf } } = item;
      d.push(acf.date);
    });
    setDates(d);
  });
  useEffect(() => {
    datesCallback();
  }, [charts]);

  useEffect(() => {
    handleWindowSizeChange();
    window.addEventListener('resize', handleWindowSizeChange);
    if (activeSection === index || isShown) {
      const time = isShown ? 100 : 1000;
      const parsetData = charts.map((item) => {
        const { node: { acf } } = item;
        return parseArabic(acf.number);
      });
      setTimeout(() => {
        document.querySelector(`.${styles.animatedTextContainerBox}`).classList.add('showLines');
        manageData(parsetData, 'add');
      }, time);
    } else {
      manageData(initialArray, 'remove');
      document.querySelector(`.${styles.animatedTextContainerBox}`).classList.remove('showLines');
    }

    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, [activeSection, isShown]);
  const dataActive = `active_${activeSection}`;
  const chart = {
    legend: {
      enabled: false,
    },
    animation: false,
    height: 460,
    backgroundColor: 'transparent',
  };
  const plotOptions = {
    enableMouseTracking: false,
    line: {
      animation: {
        duration: 10000,
        easing: 'cubic-bezier(0.7, 0, 0.84, 0)',
      },
      color: '#fff',
      connectEnds: true,
      softThreshold: false,
      states: {
        hover: {
          enabled: false,
        },
      },
    },
    column: {
      animation: {
        duration: 100,
        easing: 'cubic-bezier(0.7, 0, 0.84, 0)',
      },
      states: {
        hover: {
          enabled: false,
        },
      },
    },
    series: {
      enableMouseTracking: false,
      borderWidth: 0,
      dataLabels: {
        enabled: true,
        formatter(tooltip, y = this.y) {
          const num = isAr ? toAr(`${y}`) : y;
          return `${data.char_1}${num} ${data.char_2}`;
        },
        top: '30px',
        useHTML: true,
        style: {
          color: '#fcb600',
          fontSize: isMob ? '9px' : '16px',
          fontWeight: 400,
          letterSpacing: '-0.16px',
          stroke: '#fcb600',
          top: '-20px',
        },
      },
      color: '#3d3d3d',
      pointWidth: isMob ? '20' : '77',
      marker: {
        enabled: true,
        radius: isMob ? 5 : 7.5,
        symbol: 'circle',
        fillColor: '#fcb600',
        lineWidth: 1,
      },
    },
  };

  const xAxis = {
    labels: {
      enable: false,
      useHTML: true,
      style: {
        fontSize: isMobile ? '9px' : '20px',
        fontWeight: 400,
        letterSpacing: '-0.2px',
        color: '#ffffff',
      },
    },
    lineColor: '#ffffff',
  };
  const yAxis = {
    floor: 0,
    ceiling: 100,
    minPadding: 0,
    maxPadding: 0,
    min: 0,
    minRange: 0.1,
    max: 63,
    tickInterval: 21,
  };
  const renderSection = () => (
    <section id={replaceSpace(sectionName)} data-active={dataActive} className={`current-${index} ${styles.stockInformationSection} ${styles.sections}`}>
      <VerticalLines />
      <Container>
        <div className={styles.animatedTextContainerBox}>
          <div className={`${styles.animatedTextContainer}`}>
            <h3 className={`${styles.animatedText} ${styles.sectionTitle} ${styles.h3}`}>{`${title}`}</h3>
          </div>
          <div className={`${styles.animatedTextContainer}`}>
            <HighchartsChart chart={chart} plotOptions={plotOptions}>
              <XAxis categories={dates} {...xAxis} />

              <YAxis {...yAxis}>
                <ColumnSeries data={localData} />
                <LineSeries data={localData} />
              </YAxis>
            </HighchartsChart>
          </div>
          <div className={`${styles.animatedTextContainer}`}>
            <p className={`${styles.animatedText} ${styles.p}`}>{`${data.text}`}</p>
          </div>
        </div>
      </Container>
    </section>
  );
  return (
    isMobile
      ? (
        <ScrollAnimation
          afterAnimatedIn={() => setIsShown(true)}
          animateOnce
          initiallyVisible
          animateIn={styles.animateIn}
        >
          {Pagination}
          <LineIndicator
            height={lineHeight}
          />
          {renderSection()}
        </ScrollAnimation>
      ) : renderSection()
  );
};
StockInformationSection.propTypes = {
  charts: PropTypes.arrayOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  activeSection: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  isMobile: PropTypes.bool.isRequired,
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  Pagination: PropTypes.node.isRequired,
  sectionName: PropTypes.string.isRequired,
};

export default withHighcharts(StockInformationSection, Highcharts);
