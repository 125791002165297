import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

import styles from './code-section.module.scss';
import Container from '../../../layouts/container/container';
import VerticalLines from '../../../components/vertical-lines/ vertical-lines';
import { replaceSpace } from '../../../helpers/helpers';
import LineIndicator from '../../../components/line-indicator/line-indicator';


const CodeSection = ({
  activeSection, data, isMobile, sectionName, Pagination,
}) => {
  const [lineHeight, setLineHeight] = useState(0);

  const handleWindowSizeChange = () => {
    const pagination = document.querySelector(`.${styles.animateIn} > div`);
    const topPosition = pagination
      ? `calc(${window.getComputedStyle(pagination).top} + 103px)` : 0;
    setLineHeight(topPosition);
  };
  useEffect(() => {
    if (isMobile) {
      handleWindowSizeChange();
      window.addEventListener('resize', handleWindowSizeChange);
      window.addEventListener('scroll', handleWindowSizeChange);
    }
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
      window.removeEventListener('scroll', handleWindowSizeChange);
    };
  }, [isMobile]);
  useEffect(() => {
    if (lineHeight !== 0) {
      window.removeEventListener('scroll', handleWindowSizeChange);
    }
  }, [lineHeight]);
  const renderSection = () => (
    <section id={replaceSpace(sectionName)} data-active={`active_${activeSection}`} className={`${styles.codeSection} ${styles.sections}`}>
      <div className={styles.codeSectionOverlay} style={{ backgroundImage: `url(${data.image.url})` }} />
      <VerticalLines />
      <Container>
        <div className={styles.animatedTextContainer}>
          <div className={styles.animatedText}>
            <h6 className={styles.h3}>{data.title}</h6>
          </div>
          <div className={`${styles.animatedText} ${styles.p_bigger}`}>
            <h6 className={styles.p_bigger}>{data.text1}</h6>
          </div>
          <div className={`${styles.animatedText} ${styles.p_bigger}`}>
            <h6 className={styles.p_bigger}>{data.text2}</h6>
          </div>
          <div className={`${styles.animatedText} ${styles.p_bigger}`}>
            <h6 className={styles.p_bigger}>{data.text3}</h6>
          </div>
        </div>
      </Container>
    </section>
  );
  return (
    isMobile
      ? (
        <ScrollAnimation animateOnce initiallyVisible animateIn={styles.animateIn}>
          {Pagination}
          <LineIndicator
            height={lineHeight}
          />
          {renderSection()}
        </ScrollAnimation>
      ) : renderSection()
  );
};

CodeSection.propTypes = {
  activeSection: PropTypes.number.isRequired,
  data: PropTypes.objectOf(PropTypes.string).isRequired,
  isMobile: PropTypes.bool.isRequired,
  Pagination: PropTypes.node.isRequired,
  sectionName: PropTypes.string.isRequired,
};
export default CodeSection;
