import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

import styles from './model-section.module.scss';
import Container from '../../../layouts/container/container';
import VerticalLines from '../../../components/vertical-lines/ vertical-lines';
import LineIndicator from '../../../components/line-indicator/line-indicator';
import { replaceSpace } from '../../../helpers/helpers';


const ModelSection = ({
  activeSection, data, isMobile, sectionName, Pagination,
}) => {
  const [lineHeight, setLineHeight] = useState(0);

  const handleWindowSizeChange = () => {
    const pagination = document.querySelector(`.${styles.animateIn} > div`);
    const topPosition = pagination
      ? `calc(${window.getComputedStyle(pagination).top} + 126px)` : 0;
    setLineHeight(topPosition);
  };
  useEffect(() => {
    if (isMobile) {
      handleWindowSizeChange();
      window.addEventListener('resize', handleWindowSizeChange);
      window.addEventListener('scroll', handleWindowSizeChange);
    }
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
      window.removeEventListener('scroll', handleWindowSizeChange);
    };
  }, [isMobile]);
  useEffect(() => {
    if (lineHeight !== 0) {
      window.removeEventListener('scroll', handleWindowSizeChange);
    }
  }, [lineHeight]);
  const renderSection = () => (
    <section id={replaceSpace(sectionName)} data-active={`active_${activeSection}`} className={`${styles.modelSection} ${styles.sections}`}>
      <VerticalLines />
      <Container>
        <div className={styles.animatedTextContainer}>
          <div className={styles.animatedText}>
            <h6 className={styles.h3}>{data.title}</h6>
          </div>
          <div className={`${styles.animatedText}`}>
            <div className={styles.p}>
              {data.text1}
            </div>
            <div className={styles.p}>
              {data.text2}
            </div>
            <div className={styles.p}>
              <div>{data.listItem1}</div>
              <div>{data.listItem2}</div>
              <div>{data.listItem3}</div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
  return (isMobile
    ? (
      <ScrollAnimation animateOnce initiallyVisible animateIn={styles.animateIn}>
        {Pagination}
        <LineIndicator
          height={lineHeight}
        />
        {renderSection()}
      </ScrollAnimation>
    ) : renderSection()
  );
};

ModelSection.propTypes = {
  activeSection: PropTypes.number.isRequired,
  data: PropTypes.objectOf(PropTypes.string).isRequired,
  isMobile: PropTypes.bool.isRequired,
  Pagination: PropTypes.node.isRequired,
  sectionName: PropTypes.string.isRequired,
};
export default ModelSection;
