import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

import styles from './financials-section.module.scss';
import CarouselBlock from '../../../components/carousel/carousel';

import Icon from '../../../static/images/PDF.png';
import Container from '../../../layouts/container/container';
import VerticalLines from '../../../components/vertical-lines/ vertical-lines';
import { replaceSpace } from '../../../helpers/helpers';
import LineIndicator from '../../../components/line-indicator/line-indicator';


const FinancialsSection = ({
  activeSection,
  title,
  index,
  setCanScrollDown,
  setCanScrollUp,
  data,
  isMobile,
  sectionName,
  Pagination,
}) => {
  const [currentNumber, setCurrentNumber] = useState(1);
  const [lineHeight, setLineHeight] = useState(0);

  const handleWindowSizeChange = () => {
    const pagination = document.querySelector(`.${styles.animateIn} > div`);
    const topPosition = pagination
      ? `calc(${window.getComputedStyle(pagination).top} + 40px)` : 0;
    setLineHeight(topPosition);
  };
  useEffect(() => {
    if (isMobile) {
      handleWindowSizeChange();
      window.addEventListener('resize', handleWindowSizeChange);
      window.addEventListener('scroll', handleWindowSizeChange);
    }
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
      window.removeEventListener('scroll', handleWindowSizeChange);
    };
  }, [isMobile]);
  useEffect(() => {
    if (lineHeight !== 0) {
      window.removeEventListener('scroll', handleWindowSizeChange);
    }
  }, [lineHeight]);
  useEffect(() => {
    const div = document.querySelector(`.slick-slide.slick-active.slick-current .${styles.animatedTextContainer}`);
    const hasVerticalScrollbar = div.scrollHeight > div.clientHeight;
    if (hasVerticalScrollbar) {
      div.addEventListener('mouseover', () => {
        setCanScrollDown(true);
        setCanScrollUp(true);
      });
      div.addEventListener('mouseleave', () => {
        setCanScrollDown(false);
        setCanScrollUp(false);
      });
    }
  }, [currentNumber]);
  const setNumber = (num) => {
    setCurrentNumber(num);
  };
  const dataActive = `active_${activeSection}`;
  const renderSection = () => (
    <section id={replaceSpace(sectionName)} data-active={dataActive} className={`current-${index} ${styles.financialsSection} ${styles.sections} v4`}>
      <VerticalLines />
      <h3 className={`${styles.sectionTitle} ${styles.h3}`}>{`${title}`}</h3>
      <CarouselBlock
        getCurrentNumber={setNumber}
        index={index}
      >
        {data.map((slideData) => (
          <div className={styles.slide}>
            <Container>
              <div className={`${styles.animatedTextContainer}`}>
                <div className={styles.financialsSectionRow}>
                  {slideData.map((fileData) => {
                    if (fileData) {
                      return (
                        <a href={fileData.url} className={`animatedSliderText ${styles.p_decription}`}>
                          <img className={styles.icon} src={Icon} alt="" />
                          <p>{fileData.title}</p>
                          <p>{fileData.caption}</p>
                        </a>
                      );
                    }
                    return <></>;
                  })}
                </div>
              </div>
            </Container>
          </div>
        ))}
      </CarouselBlock>

    </section>
  );
  return (
    isMobile
      ? (
        <ScrollAnimation
          animateOnce
          initiallyVisible
          animateIn={`${styles.animateIn} animate-in`}
        >
          {Pagination}
          <LineIndicator
            height={lineHeight}
          />
          {renderSection()}
        </ScrollAnimation>
      ) : renderSection()
  );
};
FinancialsSection.propTypes = {
  activeSection: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  setCanScrollDown: PropTypes.func.isRequired,
  setCanScrollUp: PropTypes.func.isRequired,
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  isMobile: PropTypes.bool.isRequired,
  Pagination: PropTypes.node.isRequired,
  sectionName: PropTypes.string.isRequired,
};

export default FinancialsSection;
