import PropTypes from 'prop-types';
import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

import styles from './more-section.module.scss';
import Container from '../../../layouts/container/container';
import VerticalLines from '../../../components/vertical-lines/ vertical-lines';
import LinkBlock from '../../../components/link/link';

const MoreSection = ({ activeSection, data }) => (
  <ScrollAnimation initiallyVisible animateIn={styles.animateIn}>
    <section data-active={`active_${activeSection}`} className={`${styles.moreSection} ${styles.sections}`}>
      <VerticalLines />
      <Container>
        <div className={styles.animatedTextContainer}>
          <div className={styles.animatedText}>
            <h6 className={styles.h3}>{data.title}</h6>
          </div>
          <div className={styles.animatedText}>
            <LinkBlock
              path="/contact"
              name={data.link}
              withArrow
              location={{ isOpen: true }}
            />
          </div>
        </div>
      </Container>
    </section>
  </ScrollAnimation>
);

MoreSection.propTypes = {
  activeSection: PropTypes.number.isRequired,
  data: PropTypes.objectOf(PropTypes.string).isRequired,
};
export default MoreSection;
