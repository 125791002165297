import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

import styles from './shareholders-section.module.scss';
import Container from '../../../layouts/container/container';
import VerticalLines from '../../../components/vertical-lines/ vertical-lines';
import LineIndicator from '../../../components/line-indicator/line-indicator';
import { replaceSpace } from '../../../helpers/helpers';

const ShareholdersSection = ({
  activeSection, data, title, isMobile, sectionName, Pagination,
}) => {
  const [lineHeight, setLineHeight] = useState(0);

  const handleWindowSizeChange = () => {
    const pagination = document.querySelector(`.${styles.animateIn} > div`);
    const topPosition = pagination
      ? `calc(${window.getComputedStyle(pagination).top} + 80px)` : 0;
    setLineHeight(topPosition);
  };
  useEffect(() => {
    if (isMobile) {
      handleWindowSizeChange();
      window.addEventListener('resize', handleWindowSizeChange);
      window.addEventListener('scroll', handleWindowSizeChange);
    }
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
      window.removeEventListener('scroll', handleWindowSizeChange);
    };
  }, [isMobile]);
  useEffect(() => {
    if (lineHeight !== 0) {
      window.removeEventListener('scroll', handleWindowSizeChange);
    }
  }, [lineHeight]);
  const renderSection = () => (
    <section id={replaceSpace(sectionName)} data-active={`active_${activeSection}`} className={`${styles.shareholdersSection} ${styles.sections}`}>
      <VerticalLines />
      <Container>
        <div className={styles.animatedTextContainerBox}>
          <div className={`${styles.animatedTextContainer}`}>
            <h3 className={`${styles.animatedText} ${styles.sectionTitle} ${styles.h3}`}>{`${title}`}</h3>
          </div>
          <div className={styles.animatedTextContainer}>
            <table border="0" align="left">
              <tr className={`${styles.animatedText} ${styles.row1}`}>
                <th><p className={styles.p}>{data.col11}</p></th>
                <th><p className={styles.p}>{data.col21}</p></th>
                <th><p className={styles.p}>{data.col31}</p></th>
              </tr>
              <tr className={`${styles.animatedText} ${styles.row2}`}>
                <td className={styles.p}>{data.col12}</td>
                <td className={styles.p}>{data.col22}</td>
                <td className={styles.p}>{data.col32}</td>
              </tr>
              <tr className={`${styles.animatedText} ${styles.row2}`}>
                <td className={styles.p}>{data.col13}</td>
                <td className={styles.p}>{data.col23}</td>
                <td className={styles.p}>{data.col33}</td>
              </tr>
              <tr className={`${styles.animatedText} ${styles.row2}`}>
                <td className={styles.p}>{data.col14}</td>
                <td className={styles.p}>{data.col24}</td>
                <td className={styles.p}>{data.col34}</td>
              </tr>
              <tr className={`${styles.animatedText} ${styles.row3}`}>
                <td className={styles.p}>{data.col15}</td>
                <td className={styles.p}>{data.col25}</td>
                <td className={styles.p}>{data.col35}</td>
              </tr>
            </table>
          </div>
          <div className={styles.animatedTextContainer}>
            <div className={`${styles.animatedText}`}>
              <p className={styles.p}>{data.text}</p>
              <p className={styles.p}>{data.text2}</p>
              <p className={styles.p}>{data.text3}</p>
              <p className={styles.p}>{data.text4}</p>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
  return (
    isMobile
      ? (
        <ScrollAnimation animateOnce initiallyVisible animateIn={styles.animateIn}>
          {Pagination}
          <LineIndicator
            height={lineHeight}
          />
          {renderSection()}
        </ScrollAnimation>
      ) : renderSection()
  );
};

ShareholdersSection.propTypes = {
  activeSection: PropTypes.number.isRequired,
  data: PropTypes.objectOf(PropTypes.string).isRequired,
  isMobile: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  Pagination: PropTypes.node.isRequired,
  sectionName: PropTypes.string.isRequired,
};
export default ShareholdersSection;
